var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"add-user"},[(
      (!_vm.subPermission.store && !_vm.isEdit) || (!_vm.subPermission.update && _vm.isEdit)
    )?_c('content-not-view'):(_vm.isLoading)?_c('loading'):_c('b-card',{attrs:{"title":!_vm.isEdit
        ? _vm.$t('g.users/add_user/add_user')
        : _vm.$t('g.users/add_user/edit_user')}},[_c('validation-observer',{ref:"form"},[_c('b-form',[_c('b-row',[_vm._l((_vm.form_data),function(item,index){return _c('b-col',{key:index,attrs:{"md":"4"}},[(
                item.type === 'text' ||
                (item.type === 'password' && !_vm.isEdit) ||
                item.type === 'email'
              )?_c('b-form-group',{attrs:{"label":item.label,"label-for":item.name}},[_c('validation-provider',{attrs:{"name":item.label,"rules":item.rules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":item.placeholder,"type":item.type},model:{value:(item.value),callback:function ($$v) {_vm.$set(item, "value", $$v)},expression:"item.value"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$helpers.translateValidations(errors[0], item.label) : errors[0]))])]}}],null,true)})],1):_vm._e(),(item.type === 'date')?_c('b-form-group',{attrs:{"label":item.label,"label-for":item.name}},[_c('validation-provider',{attrs:{"name":item.label,"rules":item.rules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('b-form-datepicker',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":item.placeholder},model:{value:(item.value),callback:function ($$v) {_vm.$set(item, "value", $$v)},expression:"item.value"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$helpers.translateValidations(errors[0], item.label) : errors[0]))])]}}],null,true)})],1):(item.type === 'dropdown' && !item.custom && !_vm.isEdit)?_c('b-form-group',{attrs:{"label":item.label,"label-for":item.name}},[_c('validation-provider',{attrs:{"name":item.label,"rules":item.rules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('multiselect',{class:errors[0] && 'error-style',attrs:{"value":"id","label":"name","track-by":"name","options":item.options,"preserve-search":true,"close-on-select":true,"clear-on-select":false,"placeholder":item.placeholder},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
              var values = ref.values;
              var search = ref.search;
              var isOpen = ref.isOpen;
return [(values.length)?_c('span',{directives:[{name:"show",rawName:"v-show",value:(!isOpen),expression:"!isOpen"}],staticClass:"multiselect__single"},[_vm._v(" "+_vm._s(((values.length) + " " + (_vm.$t("option_selected"))))+" ")]):_vm._e()]}}],null,true),model:{value:(item.value),callback:function ($$v) {_vm.$set(item, "value", $$v)},expression:"item.value"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$helpers.translateValidations(errors[0], item.label) : errors[0]))])]}}],null,true)})],1):(item.type === 'countriesDropdown')?_c('b-form-group',{attrs:{"label":item.label,"label-for":item.name}},[_c('validation-provider',{attrs:{"name":item.label,"rules":item.rules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('multiselect',{class:errors[0] && 'error-style',attrs:{"value":"id","label":"name","track-by":"name","options":item.options,"preserve-search":true,"close-on-select":true,"clear-on-select":false,"placeholder":item.placeholder},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
              var values = ref.values;
              var search = ref.search;
              var isOpen = ref.isOpen;
return [(values.length)?_c('span',{directives:[{name:"show",rawName:"v-show",value:(!isOpen),expression:"!isOpen"}],staticClass:"multiselect__single"},[_vm._v(" "+_vm._s(((values.length) + " " + (_vm.$t("option_selected"))))+" ")]):_vm._e()]}}],null,true),model:{value:(item.value),callback:function ($$v) {_vm.$set(item, "value", $$v)},expression:"item.value"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$helpers.translateValidations(errors[0], item.label) : errors[0]))])]}}],null,true)})],1):(item.type === 'file')?_c('b-form-group',{attrs:{"label":item.label,"label-for":item.name}},[_c('validation-provider',{attrs:{"name":item.label,"rules":item.rules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('b-form-file',{class:!item.value && 'placeholder-style',attrs:{"browse-text":_vm.$t('g.browse'),"state":errors.length > 0 ? false : null,"placeholder":item.placeholder},model:{value:(item.value),callback:function ($$v) {_vm.$set(item, "value", $$v)},expression:"item.value"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t(("g.alert." + (errors[0]))) : errors[0]))]),_c('a',{attrs:{"href":_vm.currentImageLink,"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.$t("current_image"))+" ")])]}}],null,true)})],1):(item.type === 'phone')?_c('b-form-group',{attrs:{"label":item.label,"label-for":item.name}},[_c('b-input-group',{scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('vue-country-code',{ref:"vcc",refInFor:true,attrs:{"preferredCountries":['sa', 'eg', 'us'],"enabledCountryCode":true},on:{"onSelect":_vm.onSelect},model:{value:(item.countryCode),callback:function ($$v) {_vm.$set(item, "countryCode", $$v)},expression:"item.countryCode"}})]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"phone","rules":item.rules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('b-form-input',{staticClass:"phone-input",attrs:{"state":errors.length > 0 ? false : null,"placeholder":item.placeholder,"type":"number"},model:{value:(item.value),callback:function ($$v) {_vm.$set(item, "value", $$v)},expression:"item.value"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t(("g.alert." + (errors[0]))) : errors[0]))])]}}],null,true)})],1)],1):_vm._e()],1)}),(
              _vm.form_data.type.value && _vm.form_data.type.value.value === 'admin'
            )?_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('g.users/add_user/role'),"label-for":"role"}},[_c('validation-provider',{attrs:{"name":"role","rules":_vm.form_data.type.value &&
                  _vm.form_data.type.value.value === 'admin' &&
                  !_vm.isEdit
                    ? 'required'
                    : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('multiselect',{class:errors[0] && 'error-style',attrs:{"value":"id","label":"name","track-by":"name","options":_vm.rolesOptions,"preserve-search":true,"close-on-select":true,"clear-on-select":false,"placeholder":_vm.$t('g.users/add_user/choose_role')},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
                    var values = ref.values;
                    var search = ref.search;
                    var isOpen = ref.isOpen;
return [(values.length)?_c('span',{directives:[{name:"show",rawName:"v-show",value:(!isOpen),expression:"!isOpen"}],staticClass:"multiselect__single"},[_vm._v(" "+_vm._s(((values.length) + " " + (_vm.$t("option_selected"))))+" ")]):_vm._e()]}}],null,true),model:{value:(_vm.selectedRole),callback:function ($$v) {_vm.selectedRole=$$v},expression:"selectedRole"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$helpers.translateValidations(errors[0], _vm.item.label) : errors[0]))])]}}],null,false,2913365465)})],1)],1):_vm._e(),(
              _vm.form_data.type.value &&
              (_vm.form_data.type.value.value === 'student' ||
                _vm.form_data.type.value.value === 'teacher')
            )?_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('educational_year'),"label-for":"educational-year"}},[_c('validation-provider',{attrs:{"name":"educational-year","rules":_vm.form_data.type.value &&
                  (_vm.form_data.type.value.value === 'student' ||
                    _vm.form_data.type.value.value === 'teacher') &&
                  !_vm.isEdit
                    ? 'required'
                    : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('multiselect',{class:errors[0] && 'error-style',attrs:{"value":"id","label":"name","track-by":"name","multiple":_vm.form_data.type.value &&
                    _vm.form_data.type.value.value === 'teacher',"options":_vm.eduYears,"preserve-search":true,"close-on-select":_vm.form_data.type.value &&
                    _vm.form_data.type.value.value === 'student',"clear-on-select":false,"placeholder":_vm.$t('select_edu_year')},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
                    var values = ref.values;
                    var search = ref.search;
                    var isOpen = ref.isOpen;
return [(values.length)?_c('span',{directives:[{name:"show",rawName:"v-show",value:(!isOpen),expression:"!isOpen"}],staticClass:"multiselect__single"},[_vm._v(" "+_vm._s(((values.length) + " " + (_vm.$t("option_selected"))))+" ")]):_vm._e()]}}],null,true),model:{value:(_vm.selectedEduYear),callback:function ($$v) {_vm.selectedEduYear=$$v},expression:"selectedEduYear"}}),_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0] ? _vm.$t(("g.alert." + (errors[0]))) : errors[0])+" ")])]}}],null,false,800340086)})],1)],1):_vm._e(),(
              _vm.form_data.type.value && _vm.form_data.type.value.value === 'teacher'
            )?_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('educational_level'),"label-for":"educational-level"}},[_c('validation-provider',{attrs:{"name":"educational-level","rules":_vm.form_data.type.value &&
                  _vm.form_data.type.value.value === 'teacher' &&
                  !_vm.isEdit
                    ? 'required'
                    : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('multiselect',{class:errors[0] && 'error-style',attrs:{"value":"id","label":"name","track-by":"name","options":_vm.eduLevels,"preserve-search":true,"close-on-select":true,"clear-on-select":false,"placeholder":_vm.$t('select_edu_level')},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
                    var values = ref.values;
                    var search = ref.search;
                    var isOpen = ref.isOpen;
return [(values.length)?_c('span',{directives:[{name:"show",rawName:"v-show",value:(!isOpen),expression:"!isOpen"}],staticClass:"multiselect__single"},[_vm._v(" "+_vm._s(((values.length) + " " + (_vm.$t("option_selected"))))+" ")]):_vm._e()]}}],null,true),model:{value:(_vm.selectedEduLevel),callback:function ($$v) {_vm.selectedEduLevel=$$v},expression:"selectedEduLevel"}}),_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0] ? _vm.$t(("g.alert." + (errors[0]))) : errors[0])+" ")])]}}],null,false,4205721914)})],1)],1):_vm._e(),(
              _vm.form_data.type.value && _vm.form_data.type.value.value === 'teacher'
            )?_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('subjects'),"label-for":"subjects"}},[_c('validation-provider',{attrs:{"name":"subjects","rules":_vm.form_data.type.value &&
                  _vm.form_data.type.value.value === 'teacher' &&
                  !_vm.isEdit
                    ? 'required'
                    : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('multiselect',{class:errors[0] && 'error-style',attrs:{"value":"id","label":"name","track-by":"name","multiple":true,"options":_vm.subjects,"preserve-search":true,"close-on-select":false,"clear-on-select":false,"placeholder":_vm.$t('select_subjects')},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
                    var values = ref.values;
                    var search = ref.search;
                    var isOpen = ref.isOpen;
return [(values.length)?_c('span',{directives:[{name:"show",rawName:"v-show",value:(!isOpen),expression:"!isOpen"}],staticClass:"multiselect__single"},[_vm._v(" "+_vm._s(((values.length) + " " + (_vm.$t("option_selected"))))+" ")]):_vm._e()]}}],null,true),model:{value:(_vm.selectedSubject),callback:function ($$v) {_vm.selectedSubject=$$v},expression:"selectedSubject"}}),_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0] ? _vm.$t(("g.alert." + (errors[0]))) : errors[0])+" ")])]}}],null,false,2131891667)})],1)],1):_vm._e(),(
              _vm.form_data.type.value && _vm.form_data.type.value.value === 'teacher'
            )?_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('cv'),"label-for":"cv"}},[_c('validation-provider',{attrs:{"name":"cv","rules":_vm.form_data.type.value &&
                  _vm.form_data.type.value.value === 'teacher' &&
                  !_vm.isEdit
                    ? 'required'
                    : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-form-file',{class:!_vm.selectedCV && 'placeholder-style',attrs:{"browse-text":_vm.$t('g.browse'),"placeholder":_vm.$t('select_cv'),"state":errors.length > 0 ? false : null},model:{value:(_vm.selectedCV),callback:function ($$v) {_vm.selectedCV=$$v},expression:"selectedCV"}}),_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0] ? _vm.$t(("g.alert." + (errors[0]))) : errors[0])+" ")])]}}],null,false,4288167461)})],1)],1):_vm._e(),(
              _vm.form_data.type.value && _vm.form_data.type.value.value === 'teacher'
            )?_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('experince'),"label-for":"experince"}},[_c('validation-provider',{attrs:{"name":"experince","rules":_vm.form_data.type.value &&
                  _vm.form_data.type.value.value === 'teacher' &&
                  !_vm.isEdit
                    ? 'required'
                    : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"number","placeholder":_vm.$t('select_experince'),"state":errors.length > 0 ? false : null},model:{value:(_vm.selectedExperince),callback:function ($$v) {_vm.selectedExperince=$$v},expression:"selectedExperince"}}),_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0] ? _vm.$t(("g.alert." + (errors[0]))) : errors[0])+" ")])]}}],null,false,3397065786)})],1)],1):_vm._e(),(_vm.isEdit)?_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{staticClass:"d-flex align-items-center h-100"},[_c('validation-provider',{attrs:{"name":"blocked"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-form-checkbox',{attrs:{"switch":"","state":errors.length > 0 ? false : null},model:{value:(_vm.blocked),callback:function ($$v) {_vm.blocked=$$v},expression:"blocked"}},[_vm._v(" "+_vm._s(_vm.$t("g.users/add_user/is_blocked"))+" ")]),_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0] ? _vm.$t(("g.alert." + (errors[0]))) : errors[0])+" ")])]}}],null,false,3741095443)})],1)],1):_vm._e(),_c('b-col',{attrs:{"cols":"12 mt-3"}},[_c('b-button',{attrs:{"variant":_vm.isEdit ? 'success' : 'primary',"type":"submit","disabled":_vm.isLoading},on:{"click":function($event){$event.preventDefault();return _vm.validationForm.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.isEdit ? _vm.$t("g.edit") : _vm.$t("g.submit"))+" ")])],1)],2)],1)],1)],1),_c('overlay-component',{attrs:{"isLoading":_vm.overlayLoading}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="add-user">
    <content-not-view
      v-if="
        (!subPermission.store && !isEdit) || (!subPermission.update && isEdit)
      "
    />
    <loading v-else-if="isLoading" />
    <b-card
      :title="
        !isEdit
          ? $t('g.users/add_user/add_user')
          : $t('g.users/add_user/edit_user')
      "
      v-else
    >
      <!-- form -->
      <validation-observer ref="form">
        <b-form>
          <b-row>
            <b-col md="4" v-for="(item, index) in form_data" :key="index">
              <!-- Start Input -->
              <b-form-group
                v-if="
                  item.type === 'text' ||
                  (item.type === 'password' && !isEdit) ||
                  item.type === 'email'
                "
                :label="item.label"
                :label-for="item.name"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="item.label"
                  :rules="item.rules"
                >
                  <b-form-input
                    v-model="item.value"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="item.placeholder"
                    :type="item.type"
                  />
                  <small class="text-danger">{{
                    errors[0]
                      ? $helpers.translateValidations(errors[0], item.label)
                      : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
              <!-- End Input -->

              <!-- Start Date -->
              <b-form-group
                v-if="item.type === 'date'"
                :label="item.label"
                :label-for="item.name"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="item.label"
                  :rules="item.rules"
                >
                  <b-form-datepicker
                    v-model="item.value"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="item.placeholder"
                  />
                  <small class="text-danger">{{
                    errors[0]
                      ? $helpers.translateValidations(errors[0], item.label)
                      : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
              <!-- Emd Date -->

              <!-- Start Dropdown -->
              <b-form-group
                v-else-if="item.type === 'dropdown' && !item.custom && !isEdit"
                :label="item.label"
                :label-for="item.name"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="item.label"
                  :rules="item.rules"
                >
                  <multiselect
                    value="id"
                    label="name"
                    track-by="name"
                    :options="item.options"
                    :preserve-search="true"
                    :close-on-select="true"
                    :clear-on-select="false"
                    v-model="item.value"
                    :class="errors[0] && 'error-style'"
                    :placeholder="item.placeholder"
                  >
                    <template
                      slot="selection"
                      slot-scope="{ values, search, isOpen }"
                    >
                      <span
                        v-show="!isOpen"
                        v-if="values.length"
                        class="multiselect__single"
                      >
                        {{ `${values.length} ${$t("option_selected")}` }}
                      </span>
                    </template>
                  </multiselect>
                  <small class="text-danger">{{
                    errors[0]
                      ? $helpers.translateValidations(errors[0], item.label)
                      : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
              <!-- End Dropdown -->

              <!-- Start Country Dropdown -->
              <b-form-group
                v-else-if="item.type === 'countriesDropdown'"
                :label="item.label"
                :label-for="item.name"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="item.label"
                  :rules="item.rules"
                >
                  <multiselect
                    value="id"
                    label="name"
                    track-by="name"
                    :options="item.options"
                    :preserve-search="true"
                    :close-on-select="true"
                    :clear-on-select="false"
                    v-model="item.value"
                    :class="errors[0] && 'error-style'"
                    :placeholder="item.placeholder"
                  >
                    <template
                      slot="selection"
                      slot-scope="{ values, search, isOpen }"
                    >
                      <span
                        v-show="!isOpen"
                        v-if="values.length"
                        class="multiselect__single"
                      >
                        {{ `${values.length} ${$t("option_selected")}` }}
                      </span>
                    </template>
                  </multiselect>
                  <small class="text-danger">{{
                    errors[0]
                      ? $helpers.translateValidations(errors[0], item.label)
                      : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
              <!-- End Country Dropdown -->

              <!-- Start File -->
              <b-form-group
                v-else-if="item.type === 'file'"
                :label="item.label"
                :label-for="item.name"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="item.label"
                  :rules="item.rules"
                >
                  <b-form-file
                    :class="!item.value && 'placeholder-style'"
                    :browse-text="$t('g.browse')"
                    v-model="item.value"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="item.placeholder"
                  />
                  <small class="text-danger">{{
                    errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                  }}</small>
                  <a :href="currentImageLink" target="_blank">
                    {{ $t("current_image") }}
                  </a>
                </validation-provider>
              </b-form-group>
              <!-- End File -->

              <!-- Start Phone -->
              <b-form-group
                v-else-if="item.type === 'phone'"
                :label="item.label"
                :label-for="item.name"
              >
                <b-input-group>
                  <template #prepend>
                    <vue-country-code
                      v-model="item.countryCode"
                      ref="vcc"
                      @onSelect="onSelect"
                      :preferredCountries="['sa', 'eg', 'us']"
                      :enabledCountryCode="true"
                    >
                    </vue-country-code>
                  </template>
                  <validation-provider
                    #default="{ errors }"
                    name="phone"
                    :rules="item.rules"
                  >
                    <b-form-input
                      v-model="item.value"
                      :state="errors.length > 0 ? false : null"
                      :placeholder="item.placeholder"
                      type="number"
                      class="phone-input"
                    />

                    <small class="text-danger">{{
                      errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                    }}</small>
                  </validation-provider>
                </b-input-group>
              </b-form-group>
              <!-- End Phone -->
            </b-col>

            <!-- Start Role -->
            <b-col
              cols="4"
              v-if="
                form_data.type.value && form_data.type.value.value === 'admin'
              "
            >
              <b-form-group
                :label="$t('g.users/add_user/role')"
                label-for="role"
              >
                <validation-provider
                  name="role"
                  #default="{ errors }"
                  :rules="
                    form_data.type.value &&
                    form_data.type.value.value === 'admin' &&
                    !isEdit
                      ? 'required'
                      : ''
                  "
                >
                  <multiselect
                    value="id"
                    label="name"
                    track-by="name"
                    :options="rolesOptions"
                    :preserve-search="true"
                    :close-on-select="true"
                    :clear-on-select="false"
                    v-model="selectedRole"
                    :class="errors[0] && 'error-style'"
                    :placeholder="$t('g.users/add_user/choose_role')"
                  >
                    <template
                      slot="selection"
                      slot-scope="{ values, search, isOpen }"
                    >
                      <span
                        v-show="!isOpen"
                        v-if="values.length"
                        class="multiselect__single"
                      >
                        {{ `${values.length} ${$t("option_selected")}` }}
                      </span>
                    </template>
                  </multiselect>
                  <small class="text-danger">{{
                    errors[0]
                      ? $helpers.translateValidations(errors[0], item.label)
                      : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- End ٌRole -->

            <!-- Start edu year Dropdown -->
            <b-col
              cols="4"
              v-if="
                form_data.type.value &&
                (form_data.type.value.value === 'student' ||
                  form_data.type.value.value === 'teacher')
              "
            >
              <b-form-group
                :label="$t('educational_year')"
                label-for="educational-year"
              >
                <validation-provider
                  #default="{ errors }"
                  name="educational-year"
                  :rules="
                    form_data.type.value &&
                    (form_data.type.value.value === 'student' ||
                      form_data.type.value.value === 'teacher') &&
                    !isEdit
                      ? 'required'
                      : ''
                  "
                >
                  <multiselect
                    value="id"
                    label="name"
                    track-by="name"
                    :multiple="
                      form_data.type.value &&
                      form_data.type.value.value === 'teacher'
                    "
                    :options="eduYears"
                    :preserve-search="true"
                    :close-on-select="
                      form_data.type.value &&
                      form_data.type.value.value === 'student'
                    "
                    :clear-on-select="false"
                    v-model="selectedEduYear"
                    :class="errors[0] && 'error-style'"
                    :placeholder="$t('select_edu_year')"
                  >
                    <template
                      slot="selection"
                      slot-scope="{ values, search, isOpen }"
                    >
                      <span
                        v-show="!isOpen"
                        v-if="values.length"
                        class="multiselect__single"
                      >
                        {{ `${values.length} ${$t("option_selected")}` }}
                      </span>
                    </template>
                  </multiselect>
                  <small class="text-danger">
                    {{ errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0] }}
                  </small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- End edu year Dropdown -->

            <!-- Start edu level Dropdown -->
            <b-col
              cols="4"
              v-if="
                form_data.type.value && form_data.type.value.value === 'teacher'
              "
            >
              <b-form-group
                :label="$t('educational_level')"
                label-for="educational-level"
              >
                <validation-provider
                  #default="{ errors }"
                  name="educational-level"
                  :rules="
                    form_data.type.value &&
                    form_data.type.value.value === 'teacher' &&
                    !isEdit
                      ? 'required'
                      : ''
                  "
                >
                  <multiselect
                    value="id"
                    label="name"
                    track-by="name"
                    :options="eduLevels"
                    :preserve-search="true"
                    :close-on-select="true"
                    :clear-on-select="false"
                    v-model="selectedEduLevel"
                    :class="errors[0] && 'error-style'"
                    :placeholder="$t('select_edu_level')"
                  >
                    <template
                      slot="selection"
                      slot-scope="{ values, search, isOpen }"
                    >
                      <span
                        v-show="!isOpen"
                        v-if="values.length"
                        class="multiselect__single"
                      >
                        {{ `${values.length} ${$t("option_selected")}` }}
                      </span>
                    </template>
                  </multiselect>
                  <small class="text-danger">
                    {{ errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0] }}
                  </small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- End edu level Dropdown -->

            <!-- Start subject Dropdown -->
            <b-col
              cols="4"
              v-if="
                form_data.type.value && form_data.type.value.value === 'teacher'
              "
            >
              <b-form-group :label="$t('subjects')" label-for="subjects">
                <validation-provider
                  #default="{ errors }"
                  name="subjects"
                  :rules="
                    form_data.type.value &&
                    form_data.type.value.value === 'teacher' &&
                    !isEdit
                      ? 'required'
                      : ''
                  "
                >
                  <multiselect
                    value="id"
                    label="name"
                    track-by="name"
                    :multiple="true"
                    :options="subjects"
                    :preserve-search="true"
                    :close-on-select="false"
                    :clear-on-select="false"
                    v-model="selectedSubject"
                    :class="errors[0] && 'error-style'"
                    :placeholder="$t('select_subjects')"
                  >
                    <template
                      slot="selection"
                      slot-scope="{ values, search, isOpen }"
                    >
                      <span
                        v-show="!isOpen"
                        v-if="values.length"
                        class="multiselect__single"
                      >
                        {{ `${values.length} ${$t("option_selected")}` }}
                      </span>
                    </template>
                  </multiselect>

                  <small class="text-danger">
                    {{ errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0] }}
                  </small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- End subject Dropdown -->

            <!-- Start File -->
            <b-col
              cols="4"
              v-if="
                form_data.type.value && form_data.type.value.value === 'teacher'
              "
            >
              <b-form-group :label="$t('cv')" label-for="cv">
                <validation-provider
                  #default="{ errors }"
                  name="cv"
                  :rules="
                    form_data.type.value &&
                    form_data.type.value.value === 'teacher' &&
                    !isEdit
                      ? 'required'
                      : ''
                  "
                >
                  <b-form-file
                    :class="!selectedCV && 'placeholder-style'"
                    v-model="selectedCV"
                    :browse-text="$t('g.browse')"
                    :placeholder="$t('select_cv')"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">
                    {{ errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0] }}
                  </small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- End File -->

            <!-- Start experince -->
            <b-col
              cols="4"
              v-if="
                form_data.type.value && form_data.type.value.value === 'teacher'
              "
            >
              <b-form-group :label="$t('experince')" label-for="experince">
                <validation-provider
                  #default="{ errors }"
                  name="experince"
                  :rules="
                    form_data.type.value &&
                    form_data.type.value.value === 'teacher' &&
                    !isEdit
                      ? 'required'
                      : ''
                  "
                >
                  <b-form-input
                    type="number"
                    v-model="selectedExperince"
                    :placeholder="$t('select_experince')"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">
                    {{ errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0] }}
                  </small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- End experince -->

            <!-- Start block -->
            <b-col cols="4" v-if="isEdit">
              <b-form-group class="d-flex align-items-center h-100">
                <validation-provider #default="{ errors }" name="blocked">
                  <b-form-checkbox
                    switch
                    v-model="blocked"
                    :state="errors.length > 0 ? false : null"
                  >
                    {{ $t("g.users/add_user/is_blocked") }}
                  </b-form-checkbox>
                  <small class="text-danger">
                    {{ errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0] }}
                  </small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- End block -->

            <!-- Submit Button -->
            <b-col cols="12 mt-3">
              <b-button
                :variant="isEdit ? 'success' : 'primary'"
                type="submit"
                @click.prevent="validationForm"
                :disabled="isLoading"
              >
                {{ isEdit ? $t("g.edit") : $t("g.submit") }}
              </b-button>
            </b-col>
            <!-- ** Submit Button -->
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
    <overlay-component :isLoading="overlayLoading" />
  </div>
</template>

<script>
import {
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BCardText,
  BCard,
  BFormFile,
  BSpinner,
  BFormTextarea,
  BFormCheckbox,
  BFormSelect,
  BInputGroup,
  BFormDatepicker,
  BFormSelectOption,
} from "bootstrap-vue";
import { required, email, min } from "@validations";
import Loading from "@/components/loading/loading.vue";
import OverlayComponent from "@/components/shared/OverlayComponent";
import helpers from "@/composables/helper.js";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import contentNotView from "@/components/contentNotView/contentNotView.vue";
import Multiselect from "vue-multiselect";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BCardText,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BCard,
    BFormFile,
    BSpinner,
    Loading,
    Error,
    BFormTextarea,
    BFormCheckbox,
    BFormSelect,
    BFormDatepicker,
    BInputGroup,
    BFormSelectOption,
    OverlayComponent,
    contentNotView,
    Multiselect,
  },
  data() {
    return {
      required,
      email,
      min,
      userId: null,
      isEdit: false,
      isLoading: false,
      isError: null,
      overlayLoading: false,
      currentImageLink: null,
      subjects: [],
      eduYears: [],
      eduLevels: [],
      rolesOptions: [],
      selectedSubject: null,
      selectedEduYear: null,
      selectedEduLevel: null,
      selectedCV: null,
      selectedExperince: null,
      selectedRole: null,
      blocked: false,
      form_data: {
        first_name: {
          type: "text",
          value: null,
          name: "firstName",
          rules: "required",
          label: this.$t("g.users/add_user/first_name"),
          placeholder: this.$t("g.users/add_user/enter_first_name"),
        },
        middle_name: {
          type: "text",
          value: null,
          name: "middleName",
          rules: "",
          label: this.$t("g.users/add_user/middle_name"),
          placeholder: this.$t("g.users/add_user/enter_middle_name"),
        },
        last_name: {
          type: "text",
          value: null,
          name: "lastName",
          rules: "required",
          label: this.$t("g.users/add_user/last_name"),
          placeholder: this.$t("g.users/add_user/enter_last_name"),
        },
        email: {
          type: "email",
          value: null,
          name: "newUser-email",
          rules: "required",
          label: this.$t("g.users/add_user/email"),
          placeholder: this.$t("g.users/add_user/enter_email"),
        },
        birthdate: {
          type: "date",
          value: null,
          name: "birthdate",
          rules: "required",
          label: this.$t("g.users/add_user/birthdate"),
          placeholder: this.$t("g.users/add_user/select_birthdate"),
        },
        about: {
          type: "text",
          value: null,
          name: "about",
          rules: "",
          label: this.$t("g.users/add_user/about_user"),
          placeholder: this.$t("g.users/add_user/enter_about_user"),
        },
        type: {
          type: "dropdown",
          value: null,
          options: [
            { name: this.$t("g.admin"), value: "admin" },
            { name: this.$t("g.teacher"), value: "teacher" },
            { name: this.$t("g.student"), value: "student" },
            { name: this.$t("g.parent"), value: "parent" },
          ],
          name: "type",
          rules: "required",
          label: this.$t("g.users/add_user/type"),
          placeholder: this.$t("g.users/add_user/choose_type"),
        },
        password: {
          type: "password",
          value: null,
          name: "newUser-password",
          rules: "required",
          label: this.$t("g.users/add_user/password"),
          placeholder: this.$t("g.users/add_user/enter_password"),
        },
        password_confirmation: {
          type: "password",
          value: null,
          name: "passwordConfirmation",
          rules: "required",
          label: this.$t("g.users/add_user/password_confirmation"),
          placeholder: this.$t("g.users/add_user/confirm_password"),
        },
        path: {
          type: "file",
          value: null,
          name: "path",
          rules: "",
          label: this.$t("g.users/add_user/select_image"),
          placeholder: this.$t("g.users/add_user/select_image"),
        },
        country_id: {
          type: "countriesDropdown",
          options: [],
          value: null,
          name: "country",
          rules: "required",
          label: this.$t("g.users/add_user/country"),
          placeholder: this.$t("g.users/add_user/choose_country"),
        },
        phone: {
          type: "phone",
          value: null,
          countryCode: null,
          name: "phone",
          rules: "min:9|max:11",
          label: this.$t("g.users/add_user/phone"),
          placeholder: this.$t("g.users/add_user/enter_phone"),
        },
      },
    };
  },
  beforeMount() {
    this.getRoles();
    this.getCountries();
    this.getRequiredData();
    if (this.$route.params.id) {
      this.userId = this.$route.params.id;
      this.getUserData(this.$route.params.id);
      this.isEdit = true;
    }
  },
  computed: {
    subPermission() {
      this.$store.dispatch("GET_PERMISSION", "users");
      return this.$store.state.userData.sub_Permission;
    },
  },
  methods: {
    clearForm() {
      for (const i in this.form_data) {
        this.form_data[i].value = null;
        if (this.form_data[i] == "phone") this.form_data[i].countryCode = null;

        if (this.form_data[i] == "birthdate")
          this.form_data[i].placeholder = null;
      }
      this.blocked = 0;
      this.selectedEduYear = null;
      this.selectedEduLevel = null;
      this.selectedSubject = null;
      this.selectedCV = null;
      this.selectedExperince = null;
      this.selectedRole = null;
    },
    async getCountries() {
      try {
        this.loading = true;
        const response = await this.$http.get(`web/countries`);
        this.form_data.country_id.options = response.data.data;
      } catch (error) {
        this.$helpers.handleError(error);
      } finally {
        this.loading = false;
      }
    },
    async getUserData(userId) {
      try {
        this.isLoading = true;
        const response = await this.$http.get(`admin/users/${userId}`);
        for (let item in response.data.data) {
          if (this.form_data[item] !== undefined) {
            if (item === "phone") {
              if (
                response.data.data[item] &&
                response.data.data[item].length > 0
              ) {
                this.form_data[item].value =
                  response.data.data[item][0]["phone"];
              }
            } else if (item === "birthdate") {
              this.form_data[item].value = response.data.data[item];
            } else if (item === "type") {
              this.form_data[item].value = { value: response.data.data.type };
            } else {
              this.form_data[item].value = response.data.data[item];
            }
          } else {
            continue;
          }
        }
        teacherData && (this.blocked = response.data.data.is_blocked);
        const teacherData = response.data.data.teacher;

        // if teacher
        if (teacherData) {
          this.selectedEduLevel = teacherData.EduLevel;
          this.selectedEduYear = teacherData.EduYears;
          this.selectedExperince = teacherData.experience;
          this.selectedSubject = teacherData.Subjects;
        }

        // if student
        !teacherData &&
          response.data.data.eduYear &&
          (this.selectedEduYear = response.data.data.eduYear);

        // if admin
        response.data.data.roles &&
          response.data.data.roles.length > 0 &&
          (this.selectedRole = response.data.data.roles[0]);
        response.data.data.media &&
          response.data.data.media.length > 0 &&
          (this.currentImageLink = response.data.data.media[0].path);
        this.form_data.country_id.value = response.data.data.country;
      } catch (error) {
        console.log(error);
        this.$helpers.handleError(error);
      } finally {
        this.isLoading = false;
      }
    },
    async submitEdit() {
      try {
        this.overlayLoading = true;
        let formData = new FormData();
        for (let item in this.form_data) {
          if (item === "is_blocked") {
            this.form_data[item.toString()].value
              ? formData.append(item, 1)
              : formData.append(item, 0);
          } else if (item === "country_id") {
            formData.append(item, this.form_data[item.toString()].value.id);
          } else if (this.form_data[item.toString()].value) {
            formData.append(
              item,
              this.form_data[item.toString()].value.value
                ? this.form_data[item.toString()].value.value
                : this.form_data[item.toString()].value
            );
          }
        }

        formData.append("country_code", this.form_data.phone.countryCode);
        formData.append("is_blocked", this.blocked ? 1 : 0);
        if (this.selectedEduYear?.length > 0) {
          for (let item in this.selectedEduYear) {
            formData.append(
              `edu_years[${item}]`,
              this.selectedEduYear[item].id
            );
          }
        } else if (this.selectedEduYear) {
          formData.append(`eduyear_id`, this.selectedEduYear.id);
        }

        this.selectedEduLevel &&
          formData.append("edu_level_id", this.selectedEduLevel.id);

        if (this.selectedSubject?.length > 0)
          for (let item in this.selectedSubject) {
            formData.append(`subjects[${item}]`, this.selectedSubject[item].id);
          }

        this.selectedCV && formData.append("cv", this.selectedCV);

        this.selectedExperince &&
          formData.append("experience", this.selectedExperince);

        this.selectedRole && formData.append("role_id", this.selectedRole.id);

        await this.$http.post(
          `admin/users/${this.userId}?_method=put`,
          formData
        );
        helpers.makeToast(
          "success",
          this.$t("g.send.successTitle"),
          this.$t("g.send.successBody")
        );
        setTimeout(() => {
          this.$router.push({ name: "UsersList" });
        }, 1000);
      } catch (error) {
        helpers.handleError(error);
      } finally {
        this.overlayLoading = false;
      }
    },
    async submitAdd() {
      try {
        if (this.form_data.path.value === null) {
          delete this.form_data.path;
        }
        this.overlayLoading = true;
        let formData = new FormData();
        for (let item in this.form_data) {
          if (item === "country_id") {
            formData.append(item, this.form_data[item.toString()].value.id);
          } else if (item === "type") {
            this.form_data[item.toString()].value.value !== "admin" &&
              formData.append(
                item,
                this.form_data[item.toString()].value.value
              );
          } else {
            formData.append(
              item,
              this.form_data[item.toString()].value.value
                ? this.form_data[item.toString()].value.value
                : this.form_data[item.toString()].value
            );
          }
        }
        formData.append("country_code", this.form_data.phone.countryCode);
        formData.append("is_blocked", this.blocked ? 1 : 0);
        if (this.selectedEduYear?.length > 0) {
          for (let item in this.selectedEduYear) {
            formData.append(
              `edu_years[${item}]`,
              this.selectedEduYear[item].id
            );
          }
        } else if (this.selectedEduYear) {
          formData.append(`eduyear_id`, this.selectedEduYear.id);
        }

        this.selectedEduLevel &&
          formData.append("edu_level_id", this.selectedEduLevel.id);

        if (this.selectedSubject?.length > 0)
          for (let item in this.selectedSubject) {
            formData.append(`subjects[${item}]`, this.selectedSubject[item].id);
          }

        this.selectedCV && formData.append("cv", this.selectedCV);

        this.selectedExperince &&
          formData.append("experience", this.selectedExperince);

        this.selectedRole &&
          formData.append("role_id", this.selectedRole.value);

        await this.$http.post("admin/users", formData);
        helpers.makeToast(
          "success",
          this.$t("g.send.successTitle"),
          this.$t("g.send.successBody")
        );
        setTimeout(() => {
          this.$router.push({ name: "UsersList" });
        }, 500);
      } catch (error) {
        console.log(error);
        helpers.handleError(error);
      } finally {
        this.overlayLoading = false;
      }
    },
    validationForm() {
      if (this.isEdit) {
        this.$refs.form.validate().then((success) => {
          if (success) {
            this.submitEdit();
          }
        });
      } else {
        this.$refs.form.validate().then((success) => {
          if (success) {
            this.submitAdd();
          }
        });
      }
    },
    onSelect({ name, iso2, dialCode }) {
      this.form_data.phone.countryCode = dialCode;
    },
    async getRoles() {
      try {
        const request = await this.$http.get("admin/roles");
        if ([200, 201].includes(request.status)) {
          request.data.data.forEach((role) => {
            this.rolesOptions.push({
              name: role.name,
              value: role.id,
            });
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    async fetchData(url) {
      try {
        const res = await this.$http.get(url);
        return res.data;
      } catch (error) {
        throw new Error(error);
      }
    },
    async getRequiredData() {
      try {
        this.overlayLoading = true;
        const [subjectsData, eduYearsData, eduLevelsData] = await Promise.all([
          this.fetchData("web/subjects"),
          this.fetchData("web/edu-years"),
          this.fetchData("admin/edu-levels"),
        ]);
        this.subjects = subjectsData.data;
        this.eduYears = eduYearsData.data;
        this.eduLevels = eduLevelsData.data;
      } catch (error) {
        console.log(error);
      } finally {
        this.overlayLoading = false;
      }
    },
  },
  watch: {
    $route(to, from) {
      if (to.name === "AddUser") {
        this.isEdit = false;
        this.clearForm();
        this.$refs.form.reset();
      } else if (to.name === "EditUser") {
        this.isEdit = true;
      }
    },
    isEdit(newValue) {
      if (newValue) {
      }
    },
    // selectedSubject: {
    //   deep: true,
    //   handler(value) {
    //     console.log(value);
    //   },
    // },
  },
};
</script>
<style lang="scss">
.add-user {
  .dropdown-menu.show {
    left: unset !important;
  }
}
@import "./index";

.multiselect__tags {
  height: 38px;
  padding-top: 5px !important;
  min-height: 38px !important;
}
.multiselect__single {
  font-size: 14px !important;
}
.error-style {
  .multiselect__tags {
    border-color: #ea5455 !important;
  }
}

.placeholder-style {
  .custom-file-label {
    color: #adadad !important;
  }
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
